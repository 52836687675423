import {Injectable} from '@angular/core';
import {anonymousToken, loginToken, loginRefreshToken} from '../global-variable/global-variable';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  getAnonymousToken(): string | null {
    return localStorage.getItem(anonymousToken);
  }

  getLoginToken(): string | null {
    return localStorage.getItem(loginToken);
  }

  getLoginRefreshToken(): string | null {
    return localStorage.getItem(loginRefreshToken);
  }

  saveAnonymousToken(token: string): void {
    localStorage.setItem(anonymousToken, token);
  }

  saveLoginToken(token: string): void {
    localStorage.setItem(loginToken, token);
  }

  saveLoginRefreshToken(refreshToken: string): void {
    localStorage.setItem(loginRefreshToken, refreshToken);
  }

  removeAnonymousToken(): void {
    localStorage.removeItem(anonymousToken);
  }

  removeLoginToken(): void {
    localStorage.removeItem(loginToken);
  }

  removeLoginRefreshToken(): void {
    localStorage.removeItem(loginRefreshToken);
  }
}
