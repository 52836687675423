import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {TokenService} from "./token.service";

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {

  constructor(private http: HttpClient, private tokenService: TokenService) {
  }

  get baseApiUrl() {
    return environment.baseApiUrl;
  }

  get httpClient(): HttpClient {
    return this.http;
  }

  get token() {
    return this.tokenService;
  }

  abstract getPath(): string;

  abstract getHttpParams(): HttpParams | undefined;

  protected get<T>(url?: string, params?: HttpParams | any): Observable<T> {
    if (params) {
      return this.http.get<T>(this.getFullPath(url), {params});
    } else {
      return this.http.get<T>(this.getFullPath(url));
    }
  }

  protected post<T>(url?: string, body?: any, params?: HttpParams): Observable<T> {
    if (params) {
      return this.http.post<T>(this.getFullPath(url), body, {params});
    } else {
      return this.http.post<T>(this.getFullPath(url), body);
    }
  }

  protected put<T>(url?: string, body?: any, params?: HttpParams): Observable<T> {
    if (params) {
      return this.http.put<T>(this.getFullPath(url), {params}, {params});
    } else {
      return this.http.put<T>(this.getFullPath(url), body);
    }
  }

  protected purePost<T>(url: string, body?: any, headers?: HttpHeaders): Observable<T> {
    const generatedUrl = `${this.baseApiUrl}/${url}`;
    if (headers) {
      return this.http.post<T>(generatedUrl, body, {headers});
    } else {
      return this.http.post<T>(generatedUrl, body);
    }
  }

  protected pureGet<T>(url: string, headers?: HttpHeaders): Observable<T> {
    const generatedUrl = `${this.baseApiUrl}/${url}`;
    return this.http.get<T>(generatedUrl, {headers});
  }

  protected delete<T>(url?: string, params?: any, body?: any): Observable<HttpEvent<T>> {
    return this.http.request<T>('delete', this.getFullPath(url), {params, observe: 'response', body});
  }

  protected pureDelete<T>(url: string, params?: any, body?: any): Observable<HttpEvent<T>> {
    const generatedUrl = `${this.baseApiUrl}/${url}`;
    return this.http.request<T>('delete', generatedUrl, {params, observe: 'response', body});
  }

  protected getWithOptions<T>(url: string, options: any): Observable<HttpResponse<T>> {
    return this.http.get<T>(this.getFullPath(url), {params: options, observe: 'response'});
  }

  protected getBlobRequest<Blob>(url: string, params?: HttpParams) {
    return this.http.get(this.getFullPath(url), {responseType: 'blob'});
  }

  protected postBlobRequest<Blob>(url: string, body?: any) {
    return this.http.post(this.getFullPath(url), body, {responseType: 'blob'});
  }

  private getFullPath(url: string | undefined) {
    if (url && url.length > 0) {
      return `${this.baseApiUrl}/api/${this.getPath()}/${url}`;
    } else {
      return `${this.baseApiUrl}/api/${this.getPath()}`;
    }
  }
}
