import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./shared/guards/auth.guard";

const routes: Routes = [
  {path: '', loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule)},
  {path: 'about-us', loadChildren: () => import('./components/about-us/about-us.module').then(m => m.AboutUsModule)},
  // {path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)},
  // {path: 'register', loadChildren: () => import('./components/register/register.module').then(m => m.RegisterModule)},
  {path: 'results', loadChildren: () => import('./components/results/results.module').then(m => m.ResultsModule)},
  {path: 'list/:id', loadChildren: () => import('./components/gift-list/gift-list.module').then(m => m.GiftListModule)},
  {path: 'entry', loadChildren: () => import('./components/gift-list-entry/entry.module').then(m => m.EntryModule)},
  {
    path: 'agreements',
    loadChildren: () => import('./components/static-page/static-page.module').then(m => m.StaticPageModule)
  },
  // {
  //   path: 'my-account',
  //   loadChildren: () => import('./components/account/account.module').then(m => m.AccountModule),
  //   canActivate: [AuthGuard]
  // },
  {path: '', pathMatch: 'full', redirectTo: ''},
  {path: '**', redirectTo: 'results/not-found'}

]

@NgModule({
  imports: [
    [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})]
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
