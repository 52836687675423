import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OtpResInterface } from "../interface/otp-res-interface";
import { UserResInterface } from "../interface/user-res-interface";

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  resourceUrl = 'user';


  getUser(): Observable<UserResInterface> {
    return this.get();
  }

  updateUser(params: any): Observable<UserResInterface> {
    return this.put('', params)
  }

  updateNotification(params: any): Observable<any> {
    return this.post('updateNotification', params)
  }

  deleteCurrentUser(): Observable<any> {
    return this.delete('');
  }

  logout(): Observable<any> {
    return this.post('logout');
  }

  otpSend(phoneNumber: string): Observable<OtpResInterface> {
    return this.post('otp/send', phoneNumber)
  }

  otpValidate(params: any): Observable<OtpResInterface> {
    params = {
      phoneNumber: params.phoneNumber,
      otpCode: params.otpCode
    }
    return this.post('otp/validate', params)
  }

  getHttpParams(): HttpParams | undefined {
    return undefined;
  }

  getPath(): string {
    return this.resourceUrl;
  }
}
