import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { CanonicalService } from './shared/services/canonical.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private titleService: Title, private router: Router, private activatedRoute: ActivatedRoute,
              private canonicalService: CanonicalService, private meta: Meta) {
  }

  ngOnInit(): void {
    // TODO tüm sayfalar için canonical istenirse bu function tetiklenecek canonical attribute index.html'den kaldırılması gerekmetedir
    // this.canonicalService.setCanonicalURL();
    this.dynamicTitleReading();
  }

  dynamicTitleReading() {
    const appTitle = this.titleService.getTitle();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute;
        while (child.firstChild) {
          child = child.firstChild;
        }
        if (child.snapshot.data) {
          return child.snapshot.data;
        }
        return appTitle;
      })
    ).subscribe((data: any) => {
      if (data.title) {
        this.titleService.setTitle(data.title);
      }
      if (data.metaTags) {
        data.metaTags.forEach((m: MetaDefinition) => this.meta.updateTag(m));
      }
    });
  }
}
