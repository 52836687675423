import {CountdownGlobalConfig, CountdownModule} from "ngx-countdown";
import {NgModule} from "@angular/core";

function countdownConfigFactory() {
  return {leftTime: 180, format: `mm:ss`, demand: true};
}

@NgModule({
  imports: [CountdownModule],
  providers: [
    {provide: CountdownGlobalConfig, useFactory: countdownConfigFactory},
  ],
})
export class CountDownModule {
}
