<header class="sticky-header">
  <div class="header-container">
    <div class="header-logo">
      <a [routerLink]="['/']" class="text-hide">
        <img src="../../../assets/images/thumbnail_gifton-236x80.png" alt="Gifton" title="Gifton" width="236" height="80">
        Anasayfa
      </a>
    </div>
    <div class="header-btn-group">
      <a class="my-account-container {{isAuthenticate ? '' : 'd-none'}}" routerLink="/my-account">
        <div class="my-account-box"></div>
        <div class="my-account-name"></div>
      </a>
      <button class="btn btn-login js-redirect-app" *ngIf="!isAuthenticate">GİRİŞ YAP</button>
      <button class="btn btn-register js-redirect-app" *ngIf="!isAuthenticate">ÜYE OL</button>
    </div>
  </div>
</header>
