import { Component, OnInit } from '@angular/core';
import { TokenService } from "../../shared/services/token.service";
import { AuthenticateSubjectService } from "../../shared/subjects/authenticate-subject.service";
import { UserService } from "../../shared/services/user.service";
import { UserResInterface } from "../../shared/interface/user-res-interface";
import { UtilsService } from "../../shared/services/utils.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isAuthenticate = false;
  user!: UserResInterface | any;

  constructor(private tokenService: TokenService, private authenticateSubjectService: AuthenticateSubjectService,
              public userService: UserService, private utilsService: UtilsService) {
    this.listenAuthenticateService();
  }

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    if (this.isAuthenticate == true && !localStorage.getItem('userInfo')) {
      this.userService.getUser().subscribe((res: UserResInterface) => {
        this.utilsService.setUserInfoNameAndSurname(res);
        localStorage.setItem('userInfo', JSON.stringify(res));
      })
    } else if (this.isAuthenticate == true && localStorage.getItem('userInfo')) {
      this.user = localStorage.getItem('userInfo');
      this.utilsService.setUserInfoNameAndSurname(JSON.parse(this.user));
    }
  }

  listenAuthenticateService() {
    this.authenticateSubjectService.isAuthenticate().subscribe(visible => {
      this.isAuthenticate = visible;
    });
  }
}
