import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {TokenService} from "../services/token.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticateSubjectService {
  constructor(private tokenService: TokenService) {
  }

  private subject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  setAuthenticate(visible: boolean) {
    this.subject.next(visible);
  }

  isAuthenticate(): Observable<any> {
    this.subject.asObservable().subscribe(visible => {
      const accessToken = this.tokenService.getLoginToken();
      if ((!visible) && accessToken) {
        this.subject.next(true);
      }
    });
    return this.subject.asObservable();
  }
}
