import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { anonymousUsername, authorization, grantType, password, username, } from '../global-variable/global-variable';
import { TokenResInterface } from "../interface/token-res-interface";
import { environment } from '../../../environments/environment';

const headers = new HttpHeaders()
  .append(authorization, 'Basic ' + btoa(anonymousUsername + ':' + environment.oauth_secret));

@Injectable({
  providedIn: 'root'
})
export class OauthService extends BaseService {

  resourceUrl = 'oauth';

  anonymousTokenService(): Observable<TokenResInterface> {
    const params = new FormData()
    params.append('grant_type', 'client_credentials')
    params.append('scope', 'anonymous');
    return this.purePost('oauth/token', params, headers);
  }

  checkToken(token: string): Observable<string> {
    return this.purePost('oauth/check_token?token=' + token, null, headers);
  }

  login(params: any): Observable<TokenResInterface> {
    const body = new HttpParams()
      .set(username, params.phoneNumber)
      .set(password, params.otpCode)
      .set(grantType, password);
    return this.purePost(`oauth/token`, body, headers)
  }

  refreshToken(paramRefreshToken: any): Observable<any> {
    const body = new FormData();
    body.append('grant_type', 'refresh_token');
    body.append('refresh_token', paramRefreshToken);
    return this.purePost(`oauth/token`, body)
  }

  getHttpParams(): HttpParams | undefined {
    return undefined;
  }

  getPath(): string {
    return this.resourceUrl;
  }
}
