export const anonymousUsername = 'anonymous-client';
export const loginUsername = 'login-client';
export const anonymousToken = 'gifton_anonymous_access_token';
export const loginToken = 'gifton_login_access_token';
export const loginRefreshToken = 'gifton_login_refresh_token';
export const contentType = 'Content-Type';
export const authorization = 'Authorization';
export const grantType = 'grant_type';
export const username = 'username';
export const password = 'password';
export const refreshToken = 'refresh_token';
export const haratresMail = 'mailto:gifton@haratres.com';
export const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.gifton.android';
export const appStoreUrl = 'https://apps.apple.com/tr/app/gifton/id6443988516';
