import {Inject, Injectable} from '@angular/core';
import { ErrorResInterface } from "../interface/error-res-interface";
import { UserResInterface } from "../interface/user-res-interface";
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  setUserInfoNameAndSurname(params: UserResInterface) {
    this.document.querySelectorAll('.my-account-box').forEach(value => {
      value.innerHTML = params.name.charAt(0).split(' ')[0] + params.surname.charAt(0).split(' ')[0];
    })
    this.document.querySelectorAll('.my-account-name').forEach(value => {
      value.innerHTML = params.name.split(' ')[0] + ' ' + params.surname.split(' ')[0];
    })
  }

  globalNotify(message: any, type?: any) {
    var _self = this;
    const div = this.document.createElement('div');
    div.classList.add('global-notify');
    div.innerHTML = '<div class="global-notify-message ' + (type ? type : 'blue') + '">' + message + '</div>';
    this.document.body.append(div);
    setTimeout(function () {
      _self.document.querySelectorAll('.global-notify')[0].remove();
    }, 1800)
  }

  addLoader() {
    this.document.body.classList.add('page-processing');
  }

  removeLoader() {
    this.document.body.classList.remove('page-processing');
  }

  stepByStep(stepNumber: number) {
    this.document.querySelectorAll('[data-step]').forEach(e => e.classList.remove('active'));
    this.document.querySelectorAll('[data-step="' + stepNumber + '"]')[0].classList.add('active');
  }

  errorResponseHandler(error: [ErrorResInterface]) {
    for (let i = 0; i < error.length; i++) {
      let error_item = error[i];
      const div = this.document.createElement('div');
      div.innerHTML = error_item.reason;
      div.classList.add('form-error');
      div.classList.add('error-msg');
      // @ts-ignore
      this.document.querySelectorAll('[formControlName="' + error_item.fieldName + '"]')[0].parentElement.append(div)
    }
  }

  errorOtpHandler(error: string) {
    const div = this.document.createElement('div');
    div.innerHTML = error;
    div.classList.add('otp-validate-error');
    div.classList.add('error-msg');
    // @ts-ignore
    this.document.querySelectorAll('.otp-error-tag')[0].append(div);
  }

  removeError() {
    this.document.querySelectorAll('.form-error').forEach(e => e.remove());
  }

  removeOtpError() {
    this.document.querySelectorAll('.otp-validate-error').forEach(value => {
      value.remove();
    })
  }

  componentLoadUpScroll() {
    // window.scrollTo(0, 0);
  }

  otpLoadFirstOtpFocus() {
    // @ts-ignore
    this.document.getElementById('firstOtp').focus();
  }

  dynamicTabs(tabNumber: number) {
    this.document.querySelectorAll('[data-tab]').forEach(value => {
      value.classList.remove('active');
    })
    this.document.querySelectorAll('[data-tab="' + tabNumber + '"]').forEach(value => {
      value.classList.add('active');
    })
  }
}
