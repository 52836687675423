<footer>
  <section class="app-footer-bg">
    <div class="container-max-992">
      <div class="app-footer">
        <div class="app-footer-title">
          Sen de hediye listeleri oluşturmak, istediğin ürünleri eklemek ve sevdiklerinle <br> paylaşmak için mobil uygulamayı indirebilirsin!
        </div>
        <a href="{{appStoreUrl}}" target="_blank">
          <img src="assets/images/app-store.svg" alt="Gifton" width="150" height="40">
        </a>
        <a href="{{playStoreUrl}}" target="_blank">
          <img src="assets/images/google-play.svg" alt="Gifton" width="150" height="40">
        </a>
      </div>
    </div>
  </section>
</footer>
<footer>
  <div class="container-max-992">
    <div class="footer">
      <div class="footer-img">
        <a routerLink="/" class="text-hide">
          <img src="assets/images/desktop-logo.png" alt="" width="118" height="40">
          Anasayfa
        </a>
      </div>
      <div class="footer-sub">
        <div class="footer-sub-title">HAKKIMIZDA</div>
        <a class="footer-sub-item" [routerLink]="['/about-us']">Biz Kimiz?</a>
      </div>
      <div class="footer-sub">
        <div class="footer-sub-title">SÖZLEŞMELER</div>
        <a class="footer-sub-item" [routerLink]="['/agreements/user-agreement']" [queryParams]="{pageId: 'USER_AGREEMENT'}">Kullanıcı Sözleşmesi</a>
        <a class="footer-sub-item" [routerLink]="['/agreements/kvkk']" [queryParams]="{pageId: 'KVKK_AGREEMENT'}">KVKK Aydınlatma Metni</a>
      </div>
      <div class="footer-sub">
        <div class="footer-sub-title">ÜYELER</div>
        <a class="footer-sub-item js-redirect-app" href="https://play.google.com/store/apps/details?id=com.zuuks.truck.simulator.ultimate">Giriş Yap</a>
        <a class="footer-sub-item js-redirect-app" href="https://play.google.com/store/apps/details?id=com.zuuks.truck.simulator.ultimate">Kayıt Ol</a>
      </div>
    </div>
  </div>
</footer>
