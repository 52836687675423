import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CountDownModule } from "./global-config/countDown.module";
import { HtmlPipe } from './pipes/html-pipe';


@NgModule({
  declarations: [HtmlPipe],
  imports: [
    CommonModule,
    HttpClientModule,
    CountDownModule
  ],
  exports: [
    HtmlPipe
  ]
})
export class SharedModule {
}
