import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { Router } from '@angular/router';
import { TokenService } from '../services/token.service';
import { anonymousUsername, loginUsername } from "../global-variable/global-variable";
import { OauthService } from "../services/oauth.service";
import { UtilsService } from "../services/utils.service";
import { AuthenticateSubjectService } from '../subjects/authenticate-subject.service';
import { environment } from '../../../environments/environment';
import * as FormData from 'form-data';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private tokenService: TokenService, private oauthService: OauthService, private utilsService: UtilsService,
              private authenticateSubjectService: AuthenticateSubjectService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.utilsService.addLoader();
    const observable = new Observable((observer) => {
      const anonymousToken = this.tokenService.getAnonymousToken();
      const loginToken = this.tokenService.getLoginToken();
      if (anonymousToken) {
        request = request.clone({
          setHeaders: {
            Authorization: (request.url.includes('oauth/token') || request.url.includes('oauth/check_token'))
              ? ('Basic ' + btoa(request.body.updates && request.body.updates[0].param == "username" ? (loginUsername + ':' + environment.oauth_secret) : (anonymousUsername + ':' + environment.oauth_secret)))
              : ('Bearer ' + anonymousToken)
          }
        });
      } else if (loginToken) {
        request = request.clone({
          setHeaders: {
            Authorization: (request.url.includes('oauth/token') || request.url.includes('oauth/check_token'))
              ? ('Basic ' + btoa(loginUsername + ':' + environment.oauth_secret)) : ('Bearer ' + loginToken)
          }
        });
      }

      if (anonymousToken || loginToken) {
        this.request(request, next, observer);
      } else {
        const tokenReq = request.clone({
          setHeaders: {
            Authorization: ('Basic ' + btoa(anonymousUsername + ':' + environment.oauth_secret))
          },
          url: environment.baseApiUrl + '/oauth/token?grant_type=client_credentials&scope=anonymous',
          method: "POST",
        });
        next.handle(tokenReq).subscribe((res: any) => {
          if (res.body) {
            request = request.clone({
              setHeaders: {
                Authorization: ('Bearer ' + res.body.access_token)
              }
            });
          }
          if (request.headers.has('Authorization')) {
            this.tokenService.saveAnonymousToken(res.body.access_token);
            this.request(request, next, observer);
          }
        })
      }
    })
    return observable;
  }

  request(request: HttpRequest<any>, next: HttpHandler, observer: Subscriber<any>) {
    return next.handle(request).subscribe({
      next: (event: HttpEvent<any>) => {
        return observer.next(event);
      }, error: (error: HttpErrorResponse) => {
        const refreshToken = this.tokenService.getLoginRefreshToken();
        if (error.status === 401 || error.status === 403) {
          if (error.error.error == 'invalid_token' || error.error.error == 'unauthorized') {
            if (this.tokenService.getLoginToken()) {
              this.oauthService.refreshToken(refreshToken).subscribe({
                next: (res: any) => {
                  this.tokenService.saveLoginToken(res.access_token);
                  this.tokenService.saveLoginRefreshToken(res.refresh_token);
                  location.reload();
                }, error: (error) => {
                  this.tokenService.removeLoginToken();
                  this.tokenService.removeLoginRefreshToken();
                  this.router.navigate(['/login']).then(r => false)
                  this.authenticateSubjectService.setAuthenticate(false);
                }
              });
            } else if (this.tokenService.getAnonymousToken()) {
              this.oauthService.anonymousTokenService().subscribe(res => {
                this.tokenService.saveAnonymousToken(res.access_token);
                location.reload();
              })
            }
          }
        } else if (error.error.type == 'not_found') {
          this.router.navigate(['/result/not-found']).then(r => false)
        }
        this.utilsService.removeLoader();
        observer.error(error)
      }, complete: () => {
        this.utilsService.removeLoader();
      }
    })
  }
}
